@if (article$ | async; as plan) {

@if (plan.extras) {
<ng-scrollbar class="ngr-scrollbar"  visibility="visible">
    @if ((extraData$ | async); as extraData) {

    <h2>{{ plan.description }} – {{plan.title}}</h2>
    <ul class="list-unstyled">
        <li><strong>Type</strong> {{plan.extras['plan_type']}}</li>
        <li><strong>Filing Type:</strong> {{plan.extras['type_of_filing']}}</li>
        <li><strong>Filing Date:</strong> {{plan.extras['plan_filing_date'] | date}}</li>
        <li><strong>Status:</strong> {{plan.extras['plan_status']}}</li>
    </ul>

    <app-plan-view [plan]="plan.extras" [instrumentIDLookup]="extraData['instrumentIDLookup']" [classesLookup]="extraData['classesLookup']" [treatmentColumnVisibility]="extraData['treatmentColumnVisibility']"
        [situationID]="plan.extras['case_id']"></app-plan-view>

    }
</ng-scrollbar>
}

}